import { format, formatDistanceStrict, addDays, isAfter } from "date-fns";

const addDaysToDates = ({ date, days }) => {
  return addDays(new Date(date), days);
};

const currencyFormatter = (
  { locale, currency } = {
    locale: "pt-MZ",
    currency: "MZN",
  }
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });
};

const dateFormat = (date) => {
  return format(new Date(date), "dd/MM/yyyy HH:ss");
};

const dateIsAfter = (date) => {
  return !isAfter(Date.now(), new Date(date));
};

const distanceBetweenDatesInDays = ({ date, days }) => {
  const finalDate = addDays(new Date(date), days);
  const result = formatDistanceStrict(finalDate, new Date(), { unit: "day" });
  return result.split(" ");
};

const formatError = (message) => {
  const messageSplit = message.split(":");
  return messageSplit[messageSplit.length - 1].trim();
};

const formatErrors = (message) => {
  return message
    .split(":")[3]
    .split(",")[0]
    .trim();
};

const groupBy = (array, key, makeCurrentKey) => {
  return array.reduce((accumulated, item) => {
    const currentKey = makeCurrentKey(item, key);
    return {
      ...accumulated,
      [currentKey]: [...(accumulated[currentKey] || []), item],
    };
  }, {});
};

function timeDifference(current, previous) {
  const milliSecondsPerMinute = 60 * 1000;
  const milliSecondsPerHour = milliSecondsPerMinute * 60;
  const milliSecondsPerDay = milliSecondsPerHour * 24;
  const milliSecondsPerMonth = milliSecondsPerDay * 30;
  const milliSecondsPerYear = milliSecondsPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < milliSecondsPerMinute / 3) {
    // return 'just now';
    return "agora mesmo";
  }

  if (elapsed < milliSecondsPerMinute) {
    // return 'less than 1 min ago';
    return "menos de 1 minuto atrás";
  } else if (elapsed < milliSecondsPerHour) {
    return Math.round(elapsed / milliSecondsPerMinute) + " min atrás";
  } else if (elapsed < milliSecondsPerDay) {
    return Math.round(elapsed / milliSecondsPerHour) + " h atrás";
  } else if (elapsed < milliSecondsPerMonth) {
    return Math.round(elapsed / milliSecondsPerDay) + " days atrás";
  } else if (elapsed < milliSecondsPerYear) {
    return Math.round(elapsed / milliSecondsPerMonth) + " mo atrás";
  } else {
    return Math.round(elapsed / milliSecondsPerYear) + " years atrás";
  }
}

function timeDifferenceForDate(date) {
  const now = new Date();
  const updated = new Date(date).getTime();
  // console.log("date: ", date);
  // console.log("now: ", now);
  // console.log("time go: ", now.getTime() - updated);

  return timeDifference(now.getTime(), updated);
}

export {
  addDaysToDates,
  currencyFormatter,
  dateFormat,
  dateIsAfter,
  distanceBetweenDatesInDays,
  formatError,
  formatErrors,
  groupBy,
  timeDifference,
  timeDifferenceForDate,
  // injectString
};
